import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { settings } from './helpers/settings';
import web3 from './connect-web3/web3';
import Web3 from 'web3';
import Web3Context from './providers/web3-context';
import CollectionContext from './providers/collection-context';
import MarketplaceContext from './providers/marketplace-context';
import AuctionContext from './providers/auction-context';
import UserContext from './providers/user-context';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { CookiePopup } from './components/general/CookiePopup';
// COMPONENTS
import Header from './components/general/Header';
import Footer from './components/general/Footer';
import ScrollTopButton from './components/general/ScrollTopButton';
import NotFound from './components/general/NotFound';
import NoMetaMaskAlert from './components/general/NoMetaMaskAlert';
import NoContractAlert from './components/general/NoContractAlert';
import ScrollToTop from './components/general/ScrollToTop';
import ViewOnlyAlert from './components/general/ViewOnlyAlert';

// PAGES
import Home from './pages/Home';
import Contact from './pages/Contact';
import CreateItem from './pages/CreateItem';
import Explore from './pages/Explore';
import Auctions from './pages/Auctions';
import Authors from './pages/Authors';
import Search from './pages/Search';
import ItemSingle from './pages/ItemSingle';
import AuctionSingle from './pages/AuctionSingle';
import Category from './pages/Category';
import UserProfile from './pages/UserProfile/UserProfile';
import Admin from './pages/Admin';
import Activity from './pages/Activity';
import FAQ from './pages/FAQ';
import Privacy from './pages/Privacy';
import TermsService from './pages/TermsService';

// Main Style
import './App.css';
import './mode.switcher.css';
import { GraphqlClient } from './helpers/graphqlClient';
import { authenticate } from './helpers/utils';
import { darkTheme, lightTheme } from './theme';
import { theme } from './customTheme';
import { SnackbarProvider } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Box } from '@mui/system';

// CONTRACT ABIs
import Collectible from './contracts/Collectible.json';
import Marketplace from './contracts/Marketplace.json';
import UserInfo from './contracts/UserInfo.json';
import NFTAuction from './contracts/NFTAuction.json';
/*let Collectible, Marketplace, UserInfo, NFTAuction;
if (process.env.REACT_APP_OFFLINE === 'true') {
    import('./contracts/local/Collectible.json').then(
        (module) => (Collectible = module)
    );
    import('./contracts/local/Marketplace.json').then(
        (module) => (Marketplace = module)
    );
    import('./contracts/local/UserInfo.json').then(
        (module) => (UserInfo = module)
    );
    import('./contracts/local/NFTAuction.json').then(
        (module) => (NFTAuction = module)
    );
} else {
    import('./contracts/Collectible.json').then(
        (module) => (Collectible = module)
    );
    import('./contracts/Marketplace.json').then(
        (module) => (Marketplace = module)
    );
    import('./contracts/UserInfo.json').then((module) => (UserInfo = module));
    import('./contracts/NFTAuction.json').then(
        (module) => (NFTAuction = module)
    );
}*/

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
    const snackbarRef = React.useRef(null);
    const [noMetaMask, setNoMetaMask] = useState(false);
    const [noContract, setNoContract] = useState(false);
    const [registeredAlert, setRegisteredAlert] = useState(true);
    const web3Ctx = useContext(Web3Context);
    const collectionCtx = useContext(CollectionContext);
    const marketplaceCtx = useContext(MarketplaceContext);
    const auctionCtx = useContext(AuctionContext);
    const userCtx = useContext(UserContext);
    const [networkType, setNetworkType] = useState(null);
    const [topSellers, setTopSellers] = useState([]);
    const [networkId, setNetworkId] = useState(4);
    const [web3Provider, setWeb3Provider] = useState(
        window.ethereum ? new Web3(window.ethereum) : new Web3(settings.rpcUrl)
    );
    const history = useHistory();
    const [showSuccess, setShowSuccess] = useState(false);

    /*** =============================================== */
    //      GET TOP SELLERS
    /*** =============================================== */
    useEffect(() => {
        if (marketplaceCtx.topSellers && marketplaceCtx.topSellers.length > 0) {
            setTopSellers(marketplaceCtx.topSellers);
        }
    }, [marketplaceCtx.topSellers]);

    /*** =============================================== */
    //      GET ACTIVE NETWORK ID
    /*** =============================================== */
    useEffect(() => {
        async function getNetworkId() {
            if (window.ethereum) {
                const networkId = await web3Ctx.loadNetworkId(
                    new Web3(window.ethereum)
                );
                setNetworkId(networkId);
            }
        }

        getNetworkId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*** =============================================== */
    //      TOGGLE WEB3 PROVIDER
    /*** =============================================== */
    useEffect(() => {
        if (window.ethereum && networkId === settings.networkId) {
            setWeb3Provider(new Web3(window.ethereum));
        } else {
            setWeb3Provider(new Web3(settings.rpcUrl));
        }
    }, [networkId]);

    useEffect(() => {
        /*** =============================================== */
        //      CHECK IF THE BROWSER CONTAINS METAMASK
        /*** =============================================== */
        if (!web3) {
            setNoMetaMask(true);
            document.body.style.overflow = 'hidden';
            return;
        }

        /*** =============================================== */
        //      GET BLOCKCHAIN DATA
        /*** =============================================== */
        const calclateInitialSettings = async () => {
            // Request accounts acccess if needed

            // Load account
            const account = await web3Ctx.loadAccount(web3Provider);

            // Load Network ID
            const networkId = await web3Ctx.loadNetworkId(web3Provider);
            // }

            // Load Contracts
            const nftDeployedNetwork = Collectible.networks[networkId];
            const nftContract = collectionCtx.loadContract(
                web3Provider,
                Collectible,
                nftDeployedNetwork
            );
            const mktDeployedNetwork = Marketplace.networks[networkId];
            const mktContract = marketplaceCtx.loadContract(
                web3Provider,
                Marketplace,
                mktDeployedNetwork
            );
            const userDeployedNetwork = UserInfo.networks[networkId];
            const userContract = userCtx.loadContract(
                web3Provider,
                UserInfo,
                userDeployedNetwork
            );
            const auctionDeployedNetwork = NFTAuction.networks[networkId];
            const auctionContract = auctionCtx.loadContract(
                web3Provider,
                NFTAuction,
                auctionDeployedNetwork
            );

            if (nftContract) {
                // Load total Supply
                collectionCtx.loadTotalSupply(nftContract);
            } else {
                setNoContract(false);
                return;
            }

            if (auctionContract) {
                account && auctionCtx.loadUserFunds(account);

                if (window.ethereum && networkId === settings.networkId) {
                    auctionContract.events
                        .CreateAuction()
                        .on('data', (event) => {
                            //auctionCtx.setAuctionTransactionLoading(false);
                            //history.push('/auctions');
                        })
                        .on('error', (error) => {
                            console.log(error);
                        });
                    auctionContract.events
                        .CancelAuction()
                        .on('data', (event) => {
                            //auctionCtx.setAuctionTransactionLoading(false);
                            //history.push('/explore');
                        })
                        .on('error', (error) => {
                            console.log(error);
                        });

                    auctionContract.events
                        .Bid()
                        .on('data', (event) => {
                            //auctionCtx.setAuctionTransactionLoading(false);
                        })
                        .on('error', (error) => {
                            console.log(error);
                        });

                    auctionContract.events
                        .Withdraw()
                        .on('data', (event) => {
                            //auctionCtx.setAuctionTransactionLoading(false);
                        })
                        .on('error', (error) => {
                            console.log(error);
                        });

                    auctionContract.events
                        .EndAuction()
                        .on('data', (event) => {
                            //auctionCtx.setAuctionTransactionLoading(false);
                            //history.push('/explore');
                        })
                        .on('error', (error) => {
                            console.log(error);
                        });
                }
            } else {
                setNoContract(false);
                return;
            }

            if (mktContract) {
                marketplaceCtx.loadTopSellers();
                marketplaceCtx.getContractAddress(mktContract);
                account && marketplaceCtx.loadUserFunds(account);
                userCtx.getAppOwner(mktContract);

                if (window.ethereum && networkId === settings.networkId) {
                    // Event OfferFilled subscription
                    mktContract.events
                        .BoughtNFT()
                        .on('data', (event) => {
                            ///marketplaceCtx.setMktIsLoading(false);
                        })
                        .on('error', (error) => {
                            console.log(error);
                        });

                    // Event Offer subscription
                    mktContract.events
                        .Offer()
                        .on('data', (event) => {
                            //marketplaceCtx.setMktIsLoading(false);
                        })
                        .on('error', (error) => {
                            console.log(error);
                        });

                    // Event offerCancelled subscription
                    mktContract.events
                        .SaleCancelled()
                        .on('data', (event) => {
                            //marketplaceCtx.setMktIsLoading(false);
                        })
                        .on('error', (error) => {
                            console.log(error);
                        });
                }
            } else {
                setNoContract(false);
                return;
            }

            // If User contract Loaded
            if (userContract) {
                userCtx.setUserIsLoading(false);
                account && userCtx.getUserInformationFromDB(account);
            } else {
                userCtx.setUserIsLoading(true);
            }

            // account && userCtx.loadUserCreatedNftFromDB(account);
            collectionCtx.setNftIsLoading(false);
            collectionCtx.setNftTransactionLoading(false);
            marketplaceCtx.setMktIsLoading(false);
            userCtx.setUserIsLoading(false);

            if (window.ethereum && networkId === settings.networkId) {
                // Metamask Event Subscription - Account changed
                window.ethereum.on('accountsChanged', async (accounts) => {
                    // clear token storage
                    localStorage.removeItem('token');

                    const account = await web3Ctx.loadAccount(web3Provider);
                    const getUserRes = await GraphqlClient.getUser(account);
                    if (getUserRes === '404') {
                        // if user doesnt exsit, create it at first
                        userCtx.checkRegisteration(false);
                        const createUserRes = await GraphqlClient.createUser(
                            account
                        );
                        await authenticate(createUserRes.lastNonce, account);
                    }
                    userCtx.checkRegisteration(true);

                    accounts[0] && userCtx.getUserInformationFromDB(account);
                    accounts[0] && marketplaceCtx.loadUserFunds(accounts[0]);
                    accounts[0] && auctionCtx.loadUserFunds(accounts[0]);
                    setShowSuccess(true);
                });

                // Metamask Event Subscription - Network changed
                window.ethereum.on('chainChanged', (chainId) => {
                    window.location.reload();
                });

                await web3Provider.eth.net
                    .getNetworkType()
                    .then((res) => setNetworkType(res))
                    .catch((err) => console.log(err));
            }
        };

        calclateInitialSettings();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (window.ethereum && networkId !== settings.networkId) {
        // Metamask Event Subscription - Network changed
        window.ethereum.on('chainChanged', (chainId) => {
            window.location.reload();
        });
    }

    /*** =============================================== */
    //      CHECK IF USER IS REGISTERED
    /*** =============================================== */
    useEffect(() => {
        const checkRegis = async () => {
            const getUserRes = await GraphqlClient.getUser(web3Ctx.account);
            if (getUserRes === '404') {
                // if user doesnt exsit, create it at first
                userCtx.checkRegisteration(false);
                const createUserRes = await GraphqlClient.createUser(
                    web3Ctx.account
                );
                await authenticate(createUserRes.lastNonce, web3Ctx.account);
            }
            userCtx.checkRegisteration(true);
        };
        web3Ctx.account && checkRegis();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [web3Ctx.account]);

    /*** =============================================== */

    //      CLOSE REGISTERATION ALERT
    /*** =============================================== */
    function closeAlert() {
        setRegisteredAlert(false);
    }

    /*** =============================================== */
    //      RENDER ALERT IF METAMASK IS NOT EXISTED
    /*** =============================================== */
    if (noMetaMask) {
        return <NoMetaMaskAlert />;
    }

    /*** =============================================== */
    //      RENDER ALERT IF USER IS ON WRONG NETWORK
    /*** =============================================== */
    if (noContract) {
        return <NoContractAlert network={networkType} />;
    }

    return (
        <>
            <ThemeProvider
                theme={
                    marketplaceCtx.themeMode === 'light'
                        ? lightTheme
                        : darkTheme
                }
            >
                <MuiThemeProvider theme={theme}>
                    <SnackbarProvider
                        ref={snackbarRef}
                        maxSnack={3}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        action={(snackbarId) => (
                            <IconButton
                                sx={{ color: 'white' }}
                                onClick={() =>
                                    snackbarRef.current.closeSnackbar(
                                        snackbarId
                                    )
                                }
                            >
                                <CloseIcon />
                            </IconButton>
                        )}
                    >
                        <div className="d-flex flex-column pt-5 pt-lg-0">
                            {!noContract && <Header netId={networkId} />}
                            {/*            {!userCtx.userIsRegistered &&
                web3Ctx.account &&
                registeredAlert && <RegisterAlert closeAlert={closeAlert} />}*/}
                            <ScrollToTop>
                                <Switch>
                                    <Route path="/" exact>
                                        <Home topSellers={topSellers} />
                                        <ScrollTopButton />
                                    </Route>
                                    <Route path="/contact">
                                        <Contact />
                                    </Route>
                                    <Route path="/mint">
                                        <CreateItem netId={networkId} />
                                    </Route>
                                    <Route path="/explore">
                                        <Explore />
                                    </Route>
                                    {/*                                <Route path="/community">
                                    <Community />
                                </Route>*/}
                                    <Route path="/auctions">
                                        <Auctions />
                                    </Route>
                                    <Route path="/assets/:id">
                                        <ItemSingle />
                                    </Route>
                                    <Route path="/nftauction/:id">
                                        <AuctionSingle />
                                    </Route>
                                    <Route path="/categories/:category">
                                        <Category />
                                    </Route>
                                    <Route path="/search">
                                        <Search />
                                    </Route>
                                    <Route path="/activity">
                                        <Activity />
                                    </Route>
                                    <Route path="/faq">
                                        <FAQ />
                                    </Route>
                                    {(userCtx.appOwner === web3Ctx.account ||
                                        userCtx.userInformation?.admin) &&
                                        userCtx.userIsRegistered && (
                                            <Route path="/admin">
                                                <Admin />
                                            </Route>
                                        )}
                                    <Route exact path="/users/:address">
                                        <UserProfile topSellers={topSellers} />
                                    </Route>
                                    <Route path="/users/:address/:pageIdentifier">
                                        <UserProfile topSellers={topSellers} />
                                    </Route>
                                    <Route path="/sellers">
                                        <Authors sellers={topSellers} />
                                    </Route>
                                    <Route path="/privacy">
                                        <Privacy />
                                    </Route>
                                    <Route path="/terms-service">
                                        <TermsService />
                                    </Route>
                                    <Route>
                                        <NotFound />
                                    </Route>
                                </Switch>
                            </ScrollToTop>
                            <Footer />
                            <Box
                                className="fix-bottom"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <CookiePopup />
                                {(window.ethereum &&
                                    networkId === settings.networkId) || (
                                    <ViewOnlyAlert />
                                )}
                                {window.ethereum &&
                                    networkId !== settings.networkId && (
                                        <NoContractAlert />
                                    )}
                            </Box>
                        </div>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            open={showSuccess}
                            autoHideDuration={6000}
                            onClose={() => {
                                setShowSuccess(false);
                            }}
                        >
                            <Alert
                                onClose={() => {
                                    setShowSuccess(false);
                                }}
                                severity="success"
                            >
                                Account Changed!
                            </Alert>
                        </Snackbar>
                    </SnackbarProvider>
                </MuiThemeProvider>
            </ThemeProvider>
        </>
    );
}

export default App;
